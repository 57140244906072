@tailwind base;
@tailwind components;
@tailwind utilities;

html,body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.eve {
  background-image: url("assets/bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.bla{
  line-height: 2;
}


.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}
.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}
.testimonial .slick-arrow.slick-next {
  right: -70px;
}
.testimonial .slick-arrow.slick-prev {
  left: -70px;
}
.testimonial .slick-dots li button:before {
  font-size: 15px;
}
.testimonial .slick-dots li {
  top: 15px;
}